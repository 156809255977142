@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Shelf Component.
 * -----------------------------------------------------------------------------
 * Base styles for Shelf Component.
 *
 */
.shelf {
  display: grid;
  gap: var(--spacing-xl);

  &__products {
    row-gap: var(--spacing-5xl);
    column-gap: var(--spacing-m);
  }

  .hidden {
    display: none;
  }

  @include mq($until: l) {
    /**
     * Hide the last card to prevent a shelf grid with gaps on the last row.
     */
    [data-hide-cards='true'] {
      &[data-hidden-cards='1'] li:nth-last-child(-n + 1),
      &[data-hidden-cards='3'] li:nth-last-child(-n + 1) {
        display: none;
      }
    }
  }

  @include mq($from: l) {
    /**
     * Hide cards to prevent a shelf grid with gaps on the last row.
     * - Number of hidden cards dictated by the `data-hidden-cards` attribute
     */
    [data-hide-cards='true'] {
      &[data-hidden-cards='1'] li:nth-last-child(-n + 1),
      &[data-hidden-cards='2'] li:nth-last-child(-n + 2),
      &[data-hidden-cards='3'] li:nth-last-child(-n + 3) {
        display: none;
      }
    }
  }
}
