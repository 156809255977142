@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Static Page Content component.
 * -----------------------------------------------------------------------------
 * Base styles for static pages content.
 *
 */
.staticPageContent {
  gap: var(--spacing-xl);
  margin-block-end: var(--spacing-xl);
  width: 100%;

  &__img {
    @include aspect-ratio($force: true);
    // 1 / 1 aspect ratio
    --ratio-percent: 100%;
    height: auto;
    object-fit: cover;
    margin-block-end: var(--spacing-l);
    margin-block-start: var(--spacing-l);
    width: 100%;

    &--column {
      @include aspect-ratio($force: true);
      // 1 / 1 aspect ratio
      --ratio-percent: 100%;
      margin-block-end: 0;
      margin-block-start: 0;
      position: relative;
    }
  }

  @include mq($from: l) {
    margin-block-end: var(--spacing-5xl);
    margin-block-start: var(--spacing-5xl);

    &__img {
      margin-block-end: var(--spacing-3xl);
      margin-block-start: var(--spacing-3xl);

      &--column {
        margin-block-end: 0;
        margin-block-start: 0;
      }
    }
  }
}

.staticPageFullWidthSection {
  margin-left: calc(0px - (100vw - 100%) / 2);
  width: 100vw;
}
