@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Component: Add To Cart. 
 * -----------------------------------------------------------------------------
 * Base styles for site add to cart.
 *
 */
.addToCart {
  height: 40px;

  /**
   * Media queries.
   */
  @include mq($from: m) {
    height: 45px;
  }
}
.wellaStore {
  text-align: center;
}
