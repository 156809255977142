@import '@we-make-websites/styles-lib/src/config/configuration';
/*! purgecss start ignore */
.productPrice {
  &--pre-discount {
    @include text-body-small;
    text-decoration: line-through;
  }

  &--unit,
  &--discounted {
    @include text-body-small;
  }

  &--compare {
    @include text-body-small-mobile;
    text-decoration: line-through;

    &#{&} :global(.is-loading) {
      display: none;
    }
  }

  &--pre-discountMobileOnly {
    @include text-body-small;
    text-decoration: line-through;
  }

  &--unitMobileOnly,
  &--discountedMobileOnly {
    @include text-body-small;
  }

  &--compareMobileOnly {
    @include text-body-small-mobile;
  }

  &--discountedMobileOnly,
  &--discounted {
    color: var(--color-sale);
  }

  @include mq($from: l) {
    &--pre-discount {
      @include text-body-1;
      text-decoration: line-through;
    }

    &--unit,
    &--discounted {
      @include text-body-1;
    }

    &--compare {
      @include text-body-small;
      text-decoration: line-through;
    }
  }
}
/*! purgecss end ignore */
