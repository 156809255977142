@import '@we-make-websites/styles-lib/src/config/configuration';
.wishlistButton {
  @include button-reset;
  align-items: center;
  background-color: var(--color-background-light);
  border-radius: var(--spacing-s);
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
  z-index: var(--layer-raised);

  :global {
    .icon {
      height: var(--icon-2xs);
      width: var(--icon-2xs);
    }
  }

  &:disabled {
    pointer-events: none;
  }

  @include mq($from: l) {
    &:not(:global(.small)) {
      height: 26px;
      width: 26px;

      :global {
        .icon {
          height: var(--icon-xs);
          width: var(--icon-xs);
        }
      }
    }
  }
}
