@import '@we-make-websites/styles-lib/src/config/configuration';
.scrollSnapSlider {
  $parent: &;
  --dot-bg: #cacaca;
  --dot-active: #000;

  &__crop {
    position: relative;
    overflow: hidden;
    height: var(--slider-height);
  }

  &__content {
    height: var(--slider-height);
    position: relative;
    white-space: nowrap;
    scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    overscroll-behavior-x: contain;

    &[data-slide-animating] {
      overflow-x: hidden;
    }

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
  }

  &__platter {
    height: var(--slider-height);
    width: 100%;
    vertical-align: top;
    display: inline-flex;

    &[data-slide-animating] {
      animation-duration: var(--animation-length);
      animation-name: slideto;
    }

    :global(> div) {
      display: inline-block;
      scroll-snap-coordinate: left;
      scroll-snap-align: start;
    }
  }

  &__listitem {
    white-space: initial;
  }

  &__listitem:not(:last-of-type) {
    #{$parent}__itemview:not(:empty) {
      margin-right: var(--slider-spacing);
    }
  }

  &__listitem--reverse:not(:last-of-type) {
    #{$parent}__itemview {
      margin-left: var(--slider-spacing);
    }
  }

  &__itemview {
    display: flex;
    height: 100%;
    transform: var(--itemview-transform);
  }

  &__navButton {
    all: unset;
    cursor: pointer;
    position: absolute;
    display: none;
    top: var(--arrow-top-spacing, 50%);
    margin-top: -20px;

    :global(.icon) {
      width: 40px;
      height: 40px;
    }

    &--prev,
    &--nextReverse {
      left: 24px;

      :global(.icon) {
        transform: rotate(180deg);
      }
    }

    &--next,
    &--prevReverse {
      right: 24px;
    }
  }

  &__slidePips {
    display: flex;
    margin: 0 -8px;
  }

  &__slidePipButton {
    all: unset;
    cursor: pointer;
    padding: 8px;
  }

  &__dot {
    padding: 3px;
    width: 9px;
    height: 9px;
    background: var(--dot-bg);
    border-radius: 50%;
    display: block;
    border: 1px solid var(--color-black);

    &[data-current] {
      background: var(--dot-active);
      border: 1px solid var(--color-white);
    }
  }

  @media (hover: hover) {
    &__navButton {
      display: var(--nav-arrows-display);
      transition: transform 0.5s ease-in,
        opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
      transform: scale(0.8);
      /* If hover is supported, hide all arrows until carousel is hovered */
      opacity: 0;
    }

    &:hover {
      #{$parent}__navButton {
        transform: scale(1);
        opacity: var(--nav-arrows-disabled-opacity, 0.5);

        &[data-nav-available] {
          opacity: 1;
          z-index: var(--layer-flat);
        }
      }
    }
  }

  @include mq($until: l) {
    &__navButton {
      display: block;
      transition: transform 0.5s ease-in,
        opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
      transform: scale(1);
      opacity: var(--nav-arrows-disabled-opacity, 0.5);
      // top: var(--arrow-top-spacing-mobile);

      &[data-nav-available] {
        opacity: 1;
        z-index: var(--layer-flat);
      }
    }
  }
}

@keyframes slideto {
  from {
    transform: translateX(var(--animate-from-slide));
  }

  to {
    transform: translateX(var(--animate-too-slide));
  }
}
