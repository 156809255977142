@import '@we-make-websites/styles-lib/src/config/configuration';
/*! purgecss start ignore */
.badge {
  background-color: var(--color-background-light);
  border-radius: 70px;
  color: var(--color-black);
  overflow: hidden;
  width: fit-content;

  &__textContent {
    padding: var(--spacing-3xs) var(--spacing-xs);
  }

  &#{&}--proOnly {
    color: var(--color-white);
    background-color: var(--color-background-dark);
  }

  &#{&}--sale {
    background-color: var(--color-sale);
    color: var(--color-white);
  }
}
/*! purgecss end ignore */
