@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Search Content Component.
 * -----------------------------------------------------------------------------
 *
 */
.searchContent {
  &__hidden {
    display: none;
  }

  &__grid {
    column-gap: calc(var(--spacing-2xs) + var(--spacing-4xs));
    grid-auto-flow: dense;
    row-gap: var(--spacing-xl);
  }

  &__gridItemContent {
    height: 100%;
  }

  &__noResults {
    margin-block-end: var(--spacing-xl);
    margin-block-start: var(--spacing-xl);
    text-align: center;
  }

  &__loadMorePrev {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-block-end: var(--spacing-xl);
    margin-block-start: var(--spacing-xl);

    [disabled] {
      display: none;
    }
  }

  &__loadPrev {
    margin-block-end: var(--spacing-xl);
    margin-block-start: var(--spacing-xl);
  }

  &__stats {
    margin-top: var(--spacing-l);
    text-align: center;
    text-transform: initial;
  }

  @include mq($until: l) {
    /* Reduce the margin below full width sections */
    &__customItem:global(.col.xs4) {
      margin-block-end: calc(var(--spacing-l) * -1);
    }
  }

  @include mq($from: m) {
    &__loadMorePrev {
      margin-block-end: var(--spacing-5xl);
      margin-block-start: var(--spacing-6xl);
    }

    &__loadPrev {
      margin-block-end: var(--spacing-xl);
      margin-block-start: var(--spacing-xl);
    }
  }

  @include mq($from: l) {
    &__grid {
      row-gap: var(--spacing-xs);
    }

    /**
     * Add margin bottom to the in grid items.
     * - Spacing matches the margin bottom on the product cards, in order to
     *   align in grid content with the bottom of the add to cart button.
     */
    &__customItem,
    &__customItem:global(.col.xs4):not(:global(.l12)) {
      margin-block-end: var(--spacing-l);
    }

    /* Remove margin below full width sections */
    &__customItem:global(.col.l12) {
      margin-block-end: 0;
    }
  }
}
