/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable selector-class-pattern */

.reviewsContainer :global {
  width: 66%;
  margin-left: auto;
  margin-right: auto;
}

.ratingSummary[data-bv-show='rating_summary'] :global .bv_main_container {
  border: none !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.ratingSummary[data-bv-show='rating_summary']
  :global
  .bv_button_component_container
  .bv_button_buttonMinimalist,
.ratingSummary[data-bv-show='rating_summary']
  :global
  .bv_button_component_container
  .bv_button_buttonMinimalist:hover {
  text-transform: uppercase !important;
  font-size: 0.75rem !important;
}

.ratingSummary[data-bv-show='rating_summary']
  :global
  .bv_button_component_container
  .bv_button_buttonMinimalist:hover {
  color: #545454 !important;
}

@media (max-width: 1024px) {
  .inlineRating[data-bv-show='inline_rating']
    :global(.bv_main_container .bv_text) {
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-regular) !important;
  }
  .inlineRating[data-bv-show='inline_rating'] :global(.bv_stars_svg_no_wrap) {
    height: 15px !important;
    scale: 0.75 !important;
    margin-left: -14px !important;
  }
  .reviewsContainer {
    width: 100%;
    padding: var(--margin-mobile);
  }
}

@media (min-width: 1025px) {
  .inlineRating[data-bv-show='inline_rating']
    :global(.bv_main_container .bv_text) {
    font-size: var(--font-size-s) !important;
    font-weight: var(--font-weight-regular) !important;
  }
  .inlineRating[data-bv-show='inline_rating'] :global(.bv_stars_svg_no_wrap) {
    scale: 0.8 !important;
    margin-left: -20px !important;
  }
  .inlineRating[data-bv-show='inline_rating'] :global(.bv_sub_container) {
    margin-left: 1px !important;
    margin-top: -1px !important;
  }
  .inlineRating[data-bv-show='inline_rating']
    :global(.bv_stars_component_container) {
    padding-right: 0px !important;
    width: 65px !important;
    margin-left: 12px !important;
  }
  .inlineRating[data-bv-show='inline_rating']
    :global(.bv_main_container .bv_stars_component_container) {
    padding-right: 0px !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_numReviews_text),
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_avgRating_component_container) {
    font-size: var(--font-size-s) !important;
    font-weight: var(--font-weight-regular) !important;
    margin-top: 2px !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_numReviews_text:hover),
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_avgRating_component_container:hover) {
    font-size: var(--font-size-s) !important;
    font-weight: var(--font-weight-regular) !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_button_buttonMinimalist),
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_button_buttonMinimalist:hover) {
    font-size: var(--font-size-s) !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_numReviews_text) {
    margin-right: 80px !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_stars_component_container) {
    width: 65px !important;
  }
  .ratingSummary[data-bv-show='rating_summary']
    :global(.bv_main_container .bv_stars_component_container) {
    padding-right: 10px !important;
  }
}
